import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Loading from "./components/Loading";

const StrategyTool = lazy(() => import("./pages/StrategyTool"));
const SeriesGenerator = lazy(() => import("./pages/SeriesGenerator"));
const Pro = lazy(() => import("./pages/Pro"));
const Matches = lazy(() => import("./pages/Matches"));
const MatchReport = lazy(() => import("./pages/MatchReport"));
const CDL = lazy(() => import("./pages/CDL"));
const TheFlank = lazy(() => import("./pages/TheFlank"));
const Teams = lazy(() => import("./pages/Teams"));
const Standings = lazy(() => import("./pages/Standings"));
const StatsCenter = lazy(() => import("./pages/StatsCenter"));
const Leaderboards = lazy(() => import("./pages/Leaderboards"));


function App() {
  return (
  <BrowserRouter>
    <Suspense fallback={<Loading />}>
    <Switch>
      <Route path="/" exact render={() => <Redirect to={{pathname: "/pro"}} />} />
      <Route path="/pro" exact render={() => <Pro/>} />
      <Route path="/matches" exact render={() => <Redirect to={{pathname: "/matches/2021/Champs/Atlanta_Faze"}} />} />
      <Route path="/matches/:year/:event/:team" exact render={props => <Matches {...props} />} />
      <Route path="/match/:team/:teamId/:teamPosition/:opponent/:opponentId/:opponentPosition" exact render={props => <MatchReport {...props} />} />
      <Route path="/cdl" exact render={() => <CDL/>} />
      <Route path="/flank" exact render={() => <TheFlank/>} />
      <Route path="/teams" exact render={() => <Teams/>} />
      <Route path="/standings" exact render={() => <Redirect to={{pathname: "/standings/2021"}} />} />
      <Route path="/standings/:year" exact render={props => <Standings {...props} />} />
      <Route path="/stats" exact render={() => <Redirect to={{pathname: "/stats/2021/Atlanta_Faze/All"}} />} />
      <Route path="/stats/:year/:team/:event" exact render={props => <StatsCenter {...props} />} />
      <Route path="/leaderboards" exact render={() => <Redirect to={{pathname: "/leaderboards/2021/All"}} />} />
      <Route path="/leaderboards/:year/:event" exact render={props => <Leaderboards {...props} />} />
      <Route path="/series" exact render={() => <SeriesGenerator/>} />
      <Route path="/strategy" exact render={() => <StrategyTool/>} />
      <Route path="/404" exact render={() => window.location.replace("/404")} />
      <Route path="/" render={() => <Redirect to={{pathname: "/404"}} />} />
    </Switch>
    </Suspense>
  </BrowserRouter>
  );
}

export default App;
