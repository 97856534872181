import { makeStyles } from '@material-ui/core/styles';
//Color Palette:
//#050505 darkest
//#161616 second darkest
//#272727 third darkest
//#0d7377 background blue
//#14ffec accent aqua

const Styles = makeStyles((theme) => ({
    navBar: {
      backgroundColor: "#050505 !important",
      borderBottom: "1px solid #14ffec",
    },
    menuButton: {
      backgroundColor: "#050505",
      color: "#fcfcfc",
      padding: "12%",
      borderRadius: "50%",
      '&:hover': {
        backgroundColor: "#0d7377",
      }
    },
    logo: {
      width: "100px !important",
      height: "60px !important",
      transform: "scale(0.80)",
      margin: "0.5% 5% 0.5% 2%",
      '&:hover': {
        transform: "scale(0.90)",
        cursor: "pointer"
      }
    },
    navBarFirst: {
      cursor: "pointer",
      marginLeft: "4%",
      fontWeight: 400,
      fontSize: "1.8rem",
      textDecoration: "none",
      color: "#fcfcfc",
      '&:hover': {
        color: "#0d7377"
      }
    },
    navBarLink: {
      cursor: "pointer",
      marginLeft: "4%",
      fontWeight: 400,
      fontSize: "1.8rem",
      textDecoration: "none",
      color: "#fcfcfc",
      '&:hover': {
        color: "#0d7377"
      }
    },
    selectedLink: {
      cursor: "pointer",
      marginLeft: "4%",
      fontWeight: 400,
      fontSize: "1.8rem",
      textDecoration: "none",
      color: "#0d7377",
    },
    navBarLast: {
      margin: "0 4% 0 4%",
      fontWeight: 400,
      fontSize: "1.8rem",
      textDecoration: "none",
      color: "#fcfcfc",
      '&:hover': {
        color: "#0d7377"
      }
    },
    selectedLast: {
      margin: "0 4% 0 4%",
      fontWeight: 400,
      fontSize: "1.8rem",
      textDecoration: "none",
      color: "#0d7377",
    },
    carousel: {
      marginTop: "5%",
      backgroundColor: "#161616",
      width: "100%",
    },
    gunCarousel: {
      marginTop: "0%",
      padding: "0%",
      backgroundColor: "#fcfcfc",
      textAlign: "center",
      width: "100%",
    },
    carouselItem: {
      height: "100%",
      width: "100%",
    },
    menuItem: {
      color: "#fcfcfc !important",
      backgroundColor: "#161616 !important",
      '&:hover': {
        color: "black !important",
        border: "1px solid black !important",
        backgroundColor: "#fcfcfc !important",
      }
    },
    menuItemDisabled: {
      color: "#adadad !important",
      backgroundColor: "#333333 !important",
    },
    dialogTitle: {
      color: "#fcfcfc",
      backgroundColor: "#161616",
      borderBottom: "2px solid #14ffec",
    },
    dialogExit: {
      position: 'absolute !important',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#fcfcfc !important",
      '&:hover': {
        color: "#0d7377 !important",
      }
    },
    dialogDone: {
      paddingTop: "0.8%",
      paddingBottom: "0.8%",
      paddingLeft: "2.6%",
      paddingRight: "2.6%",
      marginTop: "3% !important",
      fontFamily: "Oswald !important", 
      fontSize: "1rem !important", 
      backgroundColor: "#0d7377 !important",
      color: "#fcfcfc !important",
      '&:hover': {
        backgroundColor: "#14ffec !important",
        color: "#050505 !important",
      }
    },
    snackbarDone: {
      marginTop: "6% !important",
      marginBottom: "6% !important",
      fontFamily: "Oswald !important", 
      fontSize: "0.85rem !important", 
      backgroundColor: "#0d7377 !important",
      color: "#fcfcfc !important",
      '&:hover': {
        backgroundColor: "#14ffec !important",
        color: "#050505 !important",
      }
    },
    dialogDoneMobile: {
      marginTop: "3%",
      marginBottom: "3%",
      marginLeft: "0%",
      fontFamily: "Oswald !important", 
      fontSize: "1rem !important", 
      backgroundColor: "#0d7377 !important",
      color: "#fcfcfc !important",
      '&:hover': {
        backgroundColor: "#14ffec !important",
        color: "#050505 !important",
      }
    },
    dialogDoneHolder: {
      cursor: "default",
      fontFamily: "Oswald", 
      fontSize: "1rem", 
      backgroundColor: "#fcfcfc",
      margin: "0 3%",
      '&:hover': {
        backgroundColor: "#fcfcfc",
      }
    },
    card: {
      maxWidth: "100%",
      margin: "5%",
    },
    cardContent: {
      backgroundColor: "#0d7377",
      color: "#fcfcfc",
      borderBottom: "1px solid #14ffec",
      borderTop: "1px solid #14ffec",
    },
    cardBottom: {
      color: "#fcfcfc",
      backgroundColor: "#161616",
    },
    selectItem: {
      color: "#fcfcfc !important",
      backgroundColor: "#272727 !important",
      '&:hover': {
        color: "black !important",
        backgroundColor: "#f7f7f7 !important",
        border: "1px solid black !important",
      },
      '&:focus': {
        color: "black !important",
        backgroundColor: "#f7f7f7 !important", 
      }
    },
    Paper: {
      color: "#fcfcfc",
      height: "90px",
      backgroundColor: "#272727",
    },
    divRef: {
      marginTop: "8%"
    },
    perksPaper: {
      color: "#fcfcfc",
      backgroundColor: "#272727",
    },
    perksContent: {
      backgroundColor: "#272727",
      margin: "5% 0",
      color: "#fcfcfc",
      borderBottom: "1px solid #14ffec",
      borderTop: "1px solid #14ffec",
    },
    listItem: {
      marginTop: "2.5%",
      color: "#fcfcfc",
      backgroundColor: "#272727",
      borderBottom: "1px solid #14ffec",
      borderRadius: "2%",
      '&:focus': {
        color: "black",
      }
    },
    mapPaper: {
      marginTop: "1.5%",
    },
    section: {
      height: "300px",
      width: "100%",
    },
    
    gunDisplay: {
      margin: "10%",
      opacity: "0.90",
      transform: "scale(0.95) !important",
      '&:hover': {
        opacity: "100",
        transform: "scale(1)",
      },
    },

    gunDisplayText: {
      fontFamily: "Abel !important", 
      fontSize: "1.8rem !important", 
      fontWeight: "700 !important", 
      align: "center",
      position: "relative",
      top: "45px",
    },

    mobileDisplayText: {
      fontFamily: "Abel !important", 
      fontSize: "1.8rem !important", 
      fontWeight: "700 !important", 
      align: "center",
      position: "relative",
      top: "0px",
    },
    
    leftDisplayCard: {
      marginTop: "5%",
      position: "relative",
      left: "35%",
      opacity: "0.90",
      boxShadow: "-20px 20px #0d7377 !important",
      transform: "scale(0.7) rotateX(25deg) rotateY(-45deg)",
      '&:hover': {
        opacity: "100",
        transform: "scale(0.75) rotateX(25deg) rotateY(-45deg)",
      },
    },
    leftDisplayCard2: {
      marginTop: "5%",
      position: "relative",
      left: "18%",
      opacity: "0.90",
      boxShadow: "-20px 20px #0d7377 !important",
      transform: "scale(0.7) rotateX(25deg) rotateY(-35deg) !important",
      '&:hover': {
        opacity: "100",
        transform: "scale(0.75) rotateX(25deg) rotateY(-35deg) !important",
      },
    },
    rightDisplayCard: {
      marginTop: "5%",
      position: "relative",
      right: "35%",
      opacity: "0.90",
      boxShadow: "20px 20px #0d7377 !important",
      transform: "scale(0.7) rotateX(25deg) rotateY(45deg)",
      '&:hover': {
        opacity: "100",
        transform: "scale(0.75) rotateX(25deg) rotateY(45deg)",
      },
    },
    rightDisplayCard2: {
      marginTop: "5%",
      position: "relative",
      right: "18%",
      opacity: "0.90",
      boxShadow: "20px 20px #0d7377 !important",
      transform: "scale(0.7) rotateX(25deg) rotateY(35deg)",
      '&:hover': {
        opacity: "100",
        transform: "scale(0.75) rotateX(25deg) rotateY(35deg)",
      },
    },
    centerDisplayCard: {
      opacity: "0.90",
      transform: "scale(0.7)",
      '&:hover': {
        opacity: "100",
        transform: "scale(0.75)",
      },
    },
    centerDisplayCard1: {
      marginTop: "5%",
      opacity: "0.90",
      width: "250px",
      transform: "scale(0.7)",
      '&:hover': {
        opacity: "100",
        transform: "scale(0.75)",
      },
    },
    mobileDisplayCard: {
      width: "60%", 
      height: "40%",
      position: "relative",
      left: "20%",
      transform: "scale(0.9)"
    },
    mobileDisplay: {
      marginTop: "5%",
      marginBottom: "12%"
    },
    footer: {
      marginTop: "7% !important",
      backgroundColor: "#050505 !important",
      color: "#fcfcfc !important",
      borderTop: "1px solid #14ffec !important",
    },
    footerSec1: {
      marginTop: "3% !important",
    },
    footerSec2: {
      marginTop: "1.5% !important",
    },
    footerIcon: {
      color: "#fcfcfc !important",
      '&:hover': {
        color: "#0d7377 !important",
      }
    },
    footerText: {
      marginTop: "1% !important",
      marginBottom: "3% !important",
      fontFamily: "Abel !important", 
      fontSize: "1.17rem !important", 
      fontWeight: "400 !important",
    },
    footerTitle: {
      fontFamily: "Abel !important", 
      fontSize: "1.4rem !important", 
      fontWeight: "400 !important",
    },
    footerLink: {
      cursor: "pointer",
      fontFamily: "Abel",
      fontWeight: 400,
      marginRight: "1.5%",
      marginLeft: "1.5%",
      fontSize: "1.25rem",
      textDecoration: "none",
      color: "#fcfcfc",
      '&:hover': {
        color: "#0d7377"
      }
    },
    loadingDiv: {
      textAlign: "center",
      marginTop: "30%",
      marginBottom: "30%"
    },
    teamCard: {
      margin: "10%",
      opacity: "0.90",
      '&:hover': {
        opacity: "100",
      },
    },
    profileAvatar: {
      margin: "10%",
      opacity: "0.90",
      transform: "scale(0.9)",
      borderBottom: "3px solid #161616",
      borderRadius: "5%",
      '&:hover': {
        opacity: "100",
        transform: "scale(1)",
        cursor: "pointer",
        borderBottom: "3px solid #14ffec"
      },
    },
    playerInfo: {
      marginTop: "5%",
      marginLeft: "5%",
      marginRight: "5%",
      width: "100%"
    },
    cardLogo: {
      width: "50px !important",
      height: "50px !important",
      borderRadius: "50%",
      border: "1px solid #14ffec",
      position: "absolute",
      left: "75%",
      bottom: "-8%"
    },
    flagRole: {
      backgroundColor: "#050505", 
      width: "56px !important", 
      height: "66px !important",
      position: "absolute",
      right: "84%",
      bottom: "79.3%",
      borderRadius: "7%"
    },
    mobileFlagRole: {
      backgroundColor: "#050505", 
      width: "56px !important", 
      height: "66px !important",
      position: "absolute",
      right: "82%",
      bottom: "79.3%",
      borderRadius: "7%"
    },
    ringZone: {
      paddingTop: "2%",
      width: "35px !important", 
      height: "96px !important",
      position: "absolute",
      left: "90%",
      bottom: "70%",
      borderRadius: "7%"
    },
    trophyZone: {
      backgroundColor: "#050505",
      position: "absolute",
      left: "79.5%",
      bottom: "21.3%", 
      width: "72px !important",
      height: "40px",
      borderRadius: "7%"
    },
    mobileTrophyZone: {
      backgroundColor: "#050505",
      position: "absolute",
      left: "76.5%",
      bottom: "21.3%", 
      width: "72px !important",
      height: "40px",
      borderRadius: "7%"
    },
    tableLogo: {
      width: "50px !important",
      height: "50px !important",
      marginRight: "10%",
      borderRadius: "50%"
    },
    tablePlayer: {
      width: "50px !important",
      height: "50px !important",
      opacity: "95",
      marginRight: "10%",
      borderRadius: "50%",
      border: "2px solid #161616",
      '&:hover': {
        opacity: "100",
        cursor: "pointer",
        borderBottom: "2px solid #14ffec"
      },
    },
    tableGamertag: {
      '&:hover': {
        color: "#0d7377",
        cursor: "pointer",
      },
    },
    matchLogo: {
      width: "80px !important",
      height: "80px !important",
      borderRadius: "50%",
      border: "2px solid #14ffec",
    },
    mobileMatchLogo: {
      width: "50px !important",
      height: "50px",
      borderRadius: "50% !important",
      border: "2px solid #14ffec",
    },
    mapResultLogo: {
      width: "25px !important",
      height: "25px !important",
      borderRadius: "50%",
      border: "1.6px solid #14ffec",
    },
    matchCard: {
      width: "100%",
      margin: "5%",
      opacity: "0.90",
      transform: "scale(0.9)",
      border: "4px solid #050505",
      '&:hover': {
        opacity: "100",
        transform: "scale(1)",
        cursor: "pointer",
        border: "4px solid #0d7377"
      },
    },
    mapResultCard: {
      width: "100%",
      margin: "0%",
      '&:hover': {
        opacity: "0.95",
      },
    },
    filterButton: {
      width: "100%",
      color: "#fcfcfc",
      backgroundColor: "#272727",
      borderBottom: "2px solid #272727",
      fontFamily: "Abel", 
      fontSize: "1.3rem",
      '&:hover': {
        cursor: "pointer",
        backgroundColor: "#161616",
        borderBottom: "2px solid #14ffec"
      },
    },
    chartGrid: {
      width: "100%", 
      marginTop: "5% !important", 
      padding: "2%",
      fontSize: "2rem",
      backgroundColor: "#161616",
      borderTop: "6px solid #0d7377",
      borderBottom: "6px solid #0d7377",
    },
  }));
  

export default Styles;  