import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Styles from "../Styles";


function Loading() {
    const classes = Styles();

//Render
  return (
    <div className={classes.loadingDiv}>
      <h1>Loading</h1>
      <CircularProgress />
    </div>
  );
}

export default Loading; 